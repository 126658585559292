import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  UserGroupIcon,
  AcademicCapIcon,
  BookOpenIcon,
  BookmarkSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Link, navigate } from "gatsby";
import { Header } from "../components/Header/Header";
import logoWhite from "../images/logo-white.png";
import categories from "../images/categories.svg";
import skills from "../images/skills.svg";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import { useAppDispatch } from "../store/store";
import { getEnrollments, getUsers, getEnrollment } from "../helpers/courses";
import LoaderIcon from "../images/loader.svg";

const OrgAdmin = () => {
  const [signed, setSigned] = useState(false);
  const [sponsor, setSponsor] = useState(null);

  const { items }: any = useSelector((state: RootState) => state.loginSlice);
  const userElement: any = useSelector((state: RootState) => state.userSlice);

  const [greeting, setGreeting] = useState("");
  const [students, setStudents] = useState<any>([]);
  const [mailOwn, setMail] = useState("");
  const [enrolls, setEnrolls] = useState<any>([]);
  const [loadingRoll, setLoadingRoll] = useState(true);
  const [assigned, setAssigned] = useState(0);
  const [seats, setSeats] = useState(0);

  const [loading, setLoading] = useState(true);

  const [courses, setCourses] = useState();

  useEffect(() => {
    if (items.status) {
      setMail(items.data._embedded.user.profile.login);
      setSigned(true);
    } else {
      navigate("/");
    }
  }, [items]);

  useEffect(() => {
    if (userElement.items.data !== undefined) {
      setSponsor(userElement.items.data.groups[1].profile.name);
    }
  }, [userElement]);

  const getStudents = async () => {
    setLoading(true);
    const users = await getUsers();
    setStudents(users.filter((item: any) => item.profile.login !== mailOwn));
    setLoading(false);
    // console.log(users,' information here ',mailOwn);
  };

  const getEnrollments = async () => {
    setLoading(true);
    const { sumCounter, sumQuantity, enrollmentCount } = await getEnrollment();
    setAssigned(sumCounter);
    setSeats(sumQuantity);
    setCourses(enrollmentCount);
    setLoading(false);
  };

  const getEnrollmentsUser = async () => {
    setLoadingRoll(true);
    const enrollments = await getEnrollments();
    setEnrolls(enrollments);
    setLoadingRoll(false);
  };

  useEffect(() => {
    if (enrolls) {
      if (enrolls.length) {
        let totalSeats = 0;
        let assignedSeats = 0;
        enrolls.map((item: any) => {
          totalSeats = totalSeats + parseInt(item.quantity);
          assignedSeats = assignedSeats + parseInt(item.counter);
        });
        // setSeats(totalSeats);
        // setAssigned(assignedSeats)
      }
    }
  }, [enrolls]);

  useEffect(() => {
    const s = new Date().getHours().toLocaleString();
    if (parseInt(s) >= 5 && parseInt(s) <= 11) {
      setGreeting("Good morning, ");
    } else if (parseInt(s) >= 12 && parseInt(s) <= 17) {
      setGreeting("Good afternoon, ");
    } else {
      setGreeting("Good evening, ");
    }
    getStudents();
    getEnrollmentsUser();
    getEnrollments();
  }, []);

  return (
    <Layout signed={signed}>
      <div className="bg-microsoft">
        <div className="mb-[40px]">
          {/* header */}
          {/* <Header isSignIn={signed} /> */}

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="lg:flex lg:items-center lg:justify-between pb-10">
              <div className="flex items-center">
                <img src="" alt="" />
                <div className="ml-4">
                  <p className="lg:text-[20px] ff-cg--semibold">{sponsor}</p>
                  <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                    {/* {greeting}  */}Welcome,
                    {items.data?._embedded.user.profile !== undefined ? (
                      <> {items.data?._embedded.user.profile.firstName}</>
                    ) : (
                      ""
                    )}
                  </h2>
                </div>
              </div>
              {/* <div className="lg:text-right mt-3 lg:mt-0">
                <p className="ff-cg--semibold lg:text-[30px] leading-none">It's a beautiful day!</p>
                <p>Let’s see how we can help you today</p>
              </div> */}
            </div>
            <div className="lg:grid gap-4 lg:gap-10 lg:grid-cols-12">
              <div className="lg:col-span-3 flex items-center flex-col justify-center g-rectangle pt-5 pb-5 mb-10">
                <div className="text-center z-10">
                  <AcademicCapIcon className="h-7 w-7 text-[#463eda] mx-auto" />
                  {loadingRoll ? (
                    <>
                      <img
                        src={LoaderIcon}
                        className={`w-[80px] h-[80px] mmb-4 inline-block`}
                      />
                      <p>Getting Courses</p>
                    </>
                  ) : (
                    <>
                      {!courses ? (
                        <>
                          <p className="mb-3">
                            You haven’t assign any seats yet
                          </p>
                          <button className=" w-full lg:w-fit border ff-cg--bold border-solid border-[#fdbf38] bg-[#fdbf38] px-[10px] pt-[9px] pb-[10px] rounded-full">
                            Assign Seats
                          </button>
                        </>
                      ) : (
                        <>
                          <p className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                            {courses}
                          </p>
                          <p>Courses</p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="lg:col-span-3 flex items-center flex-col justify-center g-rectangle pt-5 pb-5 mb-10">
                <div className="text-center z-10">
                  <BookOpenIcon className="h-7 w-7 text-[#da1a32] mx-auto" />
                  {loadingRoll ? (
                    <>
                      <img
                        src={LoaderIcon}
                        className={`w-[80px] h-[80px] mmb-4 inline-block`}
                      />
                      <p>Getting Seats</p>
                    </>
                  ) : (
                    <>
                      {enrolls && !enrolls.length ? (
                        <>
                          <p className="mb-3">
                            You haven’t assign any seats yet
                          </p>
                          <button className=" w-full lg:w-fit border ff-cg--bold border-solid border-[#fdbf38] bg-[#fdbf38] px-[10px] pt-[9px] pb-[10px] rounded-full">
                            Assign Seats
                          </button>
                        </>
                      ) : (
                        <>
                          <p className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                            {seats}
                          </p>
                          <p>Seats</p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* <div className="lg:col-span-3 flex items-center flex-col justify-center g-rectangle pt-5 pb-5 mb-10">
                <div className="text-center z-10">
                  <UserGroupIcon className="h-7 w-7 text-[#d69300] mx-auto"/>
                  <p className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">100</p>
                  <p>Students</p>
                </div>
              </div>
              <div className="lg:col-span-3 flex items-center flex-col justify-center g-rectangle pt-5 pb-5 mb-10">
                <div className="text-center z-10">
                  <BookmarkSquareIcon className="h-7 w-7 mx-auto"/>
                  <p className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">200</p>
                  <p>Seats Assigned</p>
                </div>
              </div> */}
              <div className="lg:col-span-3 flex items-center flex-col justify-center g-rectangle pt-5 pb-5 mb-10">
                <div className="text-center z-10">
                  <UserGroupIcon className="h-7 w-7 text-[#d69300] mx-auto" />
                  {loading ? (
                    <>
                      <img
                        src={LoaderIcon}
                        className={`w-[80px] h-[80px] mmb-4 inline-block`}
                      />
                      <p>Getting Students</p>
                    </>
                  ) : (
                    <>
                      {!students.length ? (
                        <>
                          <p className="mb-3">
                            You don’t have any students yet
                          </p>
                          <button className=" w-full lg:w-fit border ff-cg--bold border-solid border-[#fdbf38] bg-[#fdbf38] px-[10px] pt-[9px] pb-[10px] rounded-full">
                            <Link to="/add-student">Add Students</Link>
                          </button>
                        </>
                      ) : (
                        <>
                          <p className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                            {students.length}
                          </p>
                          <p>Students</p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="lg:col-span-3 flex items-center flex-col justify-center g-rectangle pt-5 pb-5 mb-10">
                <div className="text-center z-10">
                  <BookmarkSquareIcon className="h-7 w-7 mx-auto" />
                  {loading ? (
                    <>
                      <img
                        src={LoaderIcon}
                        className={`w-[80px] h-[80px] mmb-4 inline-block`}
                      />
                      <p>Getting Assigned Seats</p>
                    </>
                  ) : (
                    <>
                      {!students.length ? (
                        <>
                          <p className="mb-3">
                            You haven’t assign any seats yet
                          </p>
                          <button className=" w-full lg:w-fit border ff-cg--bold border-solid border-[#fdbf38] bg-[#fdbf38] px-[10px] pt-[9px] pb-[10px] rounded-full">
                            <Link to="/courses">Assing Seats</Link>
                          </button>
                        </>
                      ) : (
                        <>
                          <p className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                            {assigned}
                          </p>
                          <p>Seats Assigned</p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:grid gap-4 lg:gap-10 lg:grid-cols-12">
              <div className="lg:col-span-6 mb-10">
                <div className="rounded-3xl bg-white shadow-lg">
                  <div className="border-b border-solid p-5 border-[#e8e8e8] flex items-center justify-between">
                    <p className="ff-cg--semibold lg:text-[20px] leading-none">
                      Categories
                    </p>
                    <div className="flex items-center">
                      <ExclamationTriangleIcon className="h-6 w-6 mx-auto mr-1" />{" "}
                      <p>We are still gathering data</p>
                    </div>
                  </div>
                  <div className="p-10">
                    <div className="flex flex-col md:flex-row items-center">
                      <img
                        className="min-h-[200px] mb-4 md:mb-0"
                        src={categories}
                        alt=""
                      />
                      <p className="text-center md:text-end w-full md:pl-10 leading-8">
                        Here you will find the{" "}
                        <span className="ff-cg--bold">Categories</span> graph.{" "}
                        <br /> An interactive graph that will show the relative
                        amounts of data that belong to different categories
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-6 mb-10">
                <div className="rounded-3xl bg-white shadow-lg">
                  <div className="border-b border-solid p-5 border-[#e8e8e8] flex items-center justify-between">
                    <p className="ff-cg--semibold lg:text-[20px] leading-none">
                      Skills
                    </p>
                    <div className="flex items-center">
                      <ExclamationTriangleIcon className="h-6 w-6 mx-auto mr-1" />{" "}
                      <p>We are still gathering data</p>
                    </div>
                  </div>
                  <div className="p-10">
                    <div className="flex flex-col-reverse md:flex-row items-center">
                      <p className="text-center md:text-left w-full md:pr-10 leading-8">
                        Here you will find the{" "}
                        <span className="ff-cg--bold">Skills</span> graph.{" "}
                        <br /> A visual representation of the relationships
                        between skills to identify potential skill gaps and
                        improve your skillset.
                      </p>
                      <img
                        className="min-h-[200px] mb-4 md:mb-0"
                        src={skills}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default OrgAdmin;
export { Head } from "../components/Layout/Head";
